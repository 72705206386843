/** @jsx jsx */
import CitySelector from "../../containers/CitySelectorWithSubscription";
import { css, jsx } from "@emotion/core";
import { mediaFeatures, colors, HomePageBannerFontSize, InterFont } from "../../styles/common";
import { topMost } from "../../styles/base/zIndex";
import { useEffect, useState } from "react";
import { DoubleArrowRight } from "../ui/icons/DoubleArrowRight";
import cookies from "browser-cookies";
import { sendClevertapEvent } from "../../helpers/misc";
import { CLEVERTAP_ACTIONS } from "../../helpers/constants";
import { DATA_REFS } from "../../helpers/constants/dataRefs";
const enableCustomBanner = false;
const keepTextOnCustomBanner = false;
const HomePageCityDivStyles = css `
	display: flex;
	width: 80%;
	max-width: 1278px;
	margin: 0 auto 3% auto;
	background-repeat: round;
	background-color: ${colors.primaryBlue};
	border-radius: 18px;
	height: 12vw;
	max-height: 187px;
	@media screen and ${mediaFeatures["for-mobile"]} {
		display: block;
		width: 88%;
		margin-left: 6%;
		height: 138px;
		border-radius: 9px;
	}
	@media screen and ${mediaFeatures["for-tablet-only"]} {
		display: flex;
		margin-left: 6%;
		width: 89%;
		border-radius: 9px;
	}
	position: relative;
	z-index: ${topMost};
`;
const CityTextStyles = css `
	font-size: ${HomePageBannerFontSize.default};
	font-weight: bold;
	margin: auto 6%;
	width: 75%;
	color: ${colors.white};
	@media screen and ${mediaFeatures["for-mobile"]} {
		display: inline-block;
		margin: auto;
		padding: 18px;
		vertical-align: bottom;
		font-size: ${HomePageBannerFontSize.forCitySelectorMobile};
		width: 100%;
	}
	@media screen and ${mediaFeatures["for-tablet-only"]} {
		width: 68%;
		font-size: ${HomePageBannerFontSize.forCitySelectorTablet};
	}
`;
const CitySelectorStyles = css `
	align-self: center;
	width: 35%;
	display: block;
	padding-bottom: 0;
	@media screen and ${mediaFeatures["for-mobile"]} {
		width: 100%;
		margin-left: 5%;
		${enableCustomBanner ? "padding-top: 16%;" : "padding-bottom: 5%;"}
	}
	@media screen and ${mediaFeatures["for-tablet-only"]} {
		padding-bottom: 0;
	}
`;
const CustomBannerOnlineStyles = css `
	display: flex;
	width: 80%;
	max-width: 1278px;
	margin: 0 auto 3% auto;
	background-image: url("/assets/img/CityPickerNYEPc.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	border-radius: 18px;
	max-height: 171px;
	height: 11vw;
	@media screen and ${mediaFeatures["for-mobile"]} {
		background-image: url("/assets/img/CityPickerNYEMobile.png");
		display: block;
		width: 88%;
		margin-left: 6%;
		height: 150px;
		max-height: 392px;
		border-radius: 9px;
	}
	@media screen and ${mediaFeatures["for-tablet-only"]} {
		display: flex;
		margin-left: 6%;
		width: 89%;
		border-radius: 9px;
	}
`;
const CitySelectorWrapper = `
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
	z-index: ${topMost};
	font-size: 18px;
    position: fixed;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
`;
const OverlayStyle = `
	position: fixed;
	top: 0;
	bottom: 0;
	width: 100%;
	background-color: ${colors["cityOverlayBG"]};
	backdrop-filter: blur(8px);
	z-index: 119;
`;
const CitySelectorHeader = `
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 88%;
	max-width: 1278px;
	margin-bottom: 30px;
	font-size: 14px;
	@media screen and ${mediaFeatures["for-tablet-only"]} {
		width: 88%;
	}
	@media screen and ${mediaFeatures["for-pc"]} {
		font-size: 16px;
		width: 80%;
	}
`;
const CitySelectorLogo = `
	width: 122px;
	height: auto;
`;
const CitySelectorSkipButton = `
	display: flex;
	align-items: center;
	cursor: pointer;
	font-family: ${InterFont};
	font-size: 12px;
`;
const CitySelectorSkipIcon = `
	margin-left: 7px;
`;
const HomePageCitySelector = (props) => {
    const keepCityText = enableCustomBanner ? keepTextOnCustomBanner : true;
    const [overlayEnabled, setOverlayEnabled] = useState(false);
    const toggleOverlay = (desiredState) => {
        setOverlayEnabled(desiredState);
        props.toggleBodyScroll(desiredState);
    };
    useEffect(() => {
        if (props.selectedCity.toLowerCase() === "online") {
            const skipOverlayCookie = cookies.get("skip_online_overlay");
            if (!!skipOverlayCookie) {
                toggleOverlay(false);
            }
            else {
                toggleOverlay(true);
            }
        }
    }, []);
    const skipHandler = () => {
        toggleOverlay(false);
        cookies.set("skip_online_overlay", "true", { secure: true, expires: 7 });
        sendClevertapEvent(CLEVERTAP_ACTIONS.CITY_SELECTION_OVERLAY, {
            selected_city: "skip"
        });
    };
    return (overlayEnabled && props.selectedCity.toLowerCase() === "online") ? (<div>
			<div css={css(OverlayStyle)}/>
				<div css={css(CitySelectorWrapper)}>
					<div css={css(CitySelectorHeader)}>
						<img css={css(CitySelectorLogo)} src="/assets/images/logo/paytm_insider.png"/>
						<span data-ref={DATA_REFS.CITY_SELECTOR_OVERLAY_SKIP} onClick={skipHandler} css={css(CitySelectorSkipButton)}>Browse Online Events <DoubleArrowRight css={css(CitySelectorSkipIcon)}/> </span>
					</div>
					<div css={enableCustomBanner ? CustomBannerOnlineStyles : HomePageCityDivStyles} className="home-page-city-selection-section">
						<div css={CityTextStyles}>{keepCityText && <span>Find exciting events around you.</span>}</div>
						<span css={CitySelectorStyles}>
							<CitySelector isAlternateCitySelector/>
						</span>
					</div>
				</div>
			</div>) : null;
};
export default HomePageCitySelector;
