// font-family
export const Body = `font-family: Inter;`;
export const Display = `font-family: "korolev-condensed";`;
// colors
export const colors = {
    // text
    "--grey-dark-copy": "#121D34",
    "--grey-regular-copy": "#555D6D",
    "--grey-highlight-copy": "#242E44",
    "--Grey-Text-White": "#FCFCFD",
    "--Grey-ComplimentaryText": "#7A7C82",
    "--Grey-Small-Copy": "#9EA2AC",
    "--Grey-Inactive": "#CED1D5",
    // brand colors
    "--Brand-Secondary-Blue": "#31C0F0",
    "--Brand-Secondary-Pink": "#EC1066",
    "--Brand-Primary---Navy": "#0C172F",
    // background
    "--Background-BG-SecBTN-Light": "#F3F3F5",
    "--Background-BG-SecBTN-Dark": "#3D4559",
    "--Background-BG-White": "#FFFFFF",
    "--Comm-Error-Light": "#FBE8E9",
    "--Comm-Warning-Light": "#FFF9E5",
    '--Background-BG-Black': '#000000',
    // message
    "--Comm-Negative": "#DA1E28",
    "--Comm-Warning": "#FFCC33",
    "--Comm-Positive": "#52CC6A",
    // divider
    "--grey-borders-dividers": "#E7E8EA"
};
export const secondaryColor = (isOnline) => isOnline ? colors["--Brand-Secondary-Pink"] : colors["--Brand-Secondary-Blue"];
// typography
export const XXSmalMedium = `
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 133%; 
`;
export const XSmall = `
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 133%; 
`;
export const XSmallMedium = `
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 133%; 
`;
export const Small = `
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 133%;
`;
export const SmallMedium = `
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 133%;
`;
export const SmallSemiBold = `
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 133%;
`;
export const SmallBold = `
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 133%; 
`;
export const Base = `
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 133%;
`;
export const BaseMedium = `
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 133%;
`;
export const BaseBold = `
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
`;
export const Large = `
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
`;
export const LargeBold = `
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 133%; 
`;
export const Big = `
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; 
    text-transform: uppercase;
`;
// font-color util
export const FontColor = (color) => `
    color: ${colors[color]};
`;
// background-color util
export const BackgroundColor = (color) => `
    background: ${colors[color]};
`;
// Misc
export const TextOverflowEllipsis = `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: -webkit-fill-available;
`;
export const OverlapAfterNLines = (lines) => `
    overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
	display: -webkit-box;
	-webkit-line-clamp: ${lines || "2"};
	-webkit-box-orient: vertical;
`;
export const Uppercase = `text-transform: uppercase;`;
export const flexBox = (justifyContent, alignItems, flexWrap) => `
	display: flex;
	justify-content: ${justifyContent ? justifyContent : "center"};
	align-items: ${alignItems ? alignItems : "center"};
	flex-wrap: ${flexWrap ? flexWrap : "unset"};
`;
