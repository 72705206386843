/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import React, { useCallback, useEffect, useState } from "react";
import InsiderLink from "../../../containers/InsiderLinkWithSubscription";
import { CrossIcon } from "../../ui/icons/CrossIcon";
import { colors, mediaFeatures, typography } from "../../../styles/common";
import { MenuIcon } from "../../ui/icons/MenuIcon";
import { withRouter } from "react-router";
import { CLEVERTAP_ACTIONS } from "../../../helpers/constants";
import { isH5Build, sendClevertapEvent } from "../../../helpers/misc";
const { ISL_NAV_HOME_CLICKED, ISL_NAV_STANDINGS_CLICKED } = CLEVERTAP_ACTIONS;
const navRoute = [
    {
        title: 'Home',
        link: '/isl-indian-super-league-2023-24',
        insiderLink: true,
        eventName: ISL_NAV_HOME_CLICKED
    },
    {
        title: 'Schedule',
        link: '/isl-indian-super-league-2023-24/schedule',
        insiderLink: true,
        eventName: ''
    },
    {
        title: 'Standings',
        link: 'https://www.indiansuperleague.com/standings',
        insiderLink: false,
        eventName: ISL_NAV_STANDINGS_CLICKED
    }
];
const ISLHeader = ({ isFromApp = false, location }) => {
    const [openMobileMenu, setOpenMobileMenu] = useState(false);
    const [navbarProp, setNavbarProp] = useState(headerContainer + absoluteTransparentHeader(isFromApp !== null && isFromApp !== void 0 ? isFromApp : false));
    const [landingProp, setLandingProp] = useState('');
    const sendEvent = (event) => {
        if (event && event !== '')
            sendClevertapEvent(event);
    };
    const listenScrollEvent = useCallback((e) => {
        if (window.scrollY > 62) {
            setLandingProp(fixedHeader);
        }
        else
            setLandingProp('');
    }, [window]);
    useEffect(() => {
        let currentPath = location.pathname + location.search;
        currentPath = currentPath.split('?')[0];
        if (currentPath !== '/isl-indian-super-league-2023-24') {
            setNavbarProp(navbarProp + stickyHeader);
        }
        else {
            const absoluteCss = absoluteTransparentHeader(isFromApp !== null && isFromApp !== void 0 ? isFromApp : false);
            setNavbarProp(navbarProp + absoluteCss);
        }
        window.addEventListener('scroll', listenScrollEvent);
    }, [listenScrollEvent, location]);
    const getRouteJsx = useCallback((item, isMobile) => {
        var _a;
        let currentPath = location.pathname + location.search;
        currentPath = currentPath.split('?')[0];
        const isInsiderLink = (_a = item === null || item === void 0 ? void 0 : item.insiderLink) !== null && _a !== void 0 ? _a : true;
        const navCss = isMobile ? mobileNavItem(currentPath === item.link) : navItem(currentPath === item.link);
        return isInsiderLink ?
            <InsiderLink key={item.title} href={item.link}>
                <div css={css([navCss])} onClick={() => {
                    var _a;
                    sendEvent((_a = item === null || item === void 0 ? void 0 : item.eventName) !== null && _a !== void 0 ? _a : '');
                    if (isMobile)
                        setOpenMobileMenu(false);
                }}>
                    <p>{item.title}</p>
                </div>
            </InsiderLink>
            :
                <a key={item.title} href={item.link} {...!(isFromApp || isH5Build) && { target: '_blank' }}>
                <div css={css([navCss])} onClick={() => {
                        var _a;
                        sendEvent((_a = item === null || item === void 0 ? void 0 : item.eventName) !== null && _a !== void 0 ? _a : '');
                        if (isMobile)
                            setOpenMobileMenu(false);
                    }}>
                    <p>{item.title}</p>
                </div>
            </a>;
    }, [location, isFromApp, isH5Build]);
    return (<div id='stickyIslHeader' css={css([navbarProp, landingProp])}>
            <div css={css([webNavCont(openMobileMenu)])}>
                <InsiderLink href='/isl-indian-super-league-2023-24'>
                    <div css={css(logoCont)}>
                        <img src="/assets/images/ISL/islLogo.svg" alt="isl Logo"/>
                        <span>ISL 2023-24</span>
                    </div>
                </InsiderLink>
                <div css={css([navItemCont, HideWebNav])}>
                    {navRoute.map((item) => getRouteJsx(item, false))}
                </div>
                <div css={css([`display: none;`, showMobileNav])}>
                    <div css={css([mobileNavItem()])} onClick={() => {
            setOpenMobileMenu(!openMobileMenu);
        }}> 
                        {openMobileMenu ? <CrossIcon strokeColor={colors.white} backgroundColor="transparent"/> : <MenuIcon overideStyle={{ 'transform': 'rotateY(180deg)' }}/>}
                    </div>
                </div>
            </div>
            {openMobileMenu &&
            <div css={css([mobileNavItemCont])}>
                    <div css={css([horizontalLine])}/>
                    {navRoute.map((item) => getRouteJsx(item, true))}
                </div>}
        </div>);
};
export default withRouter(React.memo(ISLHeader));
const headerContainer = `
    background-color: ${colors.blueBg};
    transition: all 0.1s ease-in-out;
    width: 100%;
    transition: position 2s ease-in-out;
    padding: 2px 0;
    @media only screen and ${mediaFeatures['for-mobile-and-small-tablet']} { 
        border-bottom: 0;
    }
`;
const absoluteTransparentHeader = (fromApp) => `
    position: absolute;
    z-index: 118;
    top: ${(fromApp || isH5Build) ? 0 : '128px'};
    background-color: transparent;
    @media only screen and ${mediaFeatures['for-mobile-and-small-tablet']} { 
        top: ${(fromApp || isH5Build) ? 0 : '118px'};
    }
`;
const fixedHeader = `
    position: fixed;
    z-index: 119;
    top: 0;
    background-color: ${colors.blueBg};
    @media only screen and ${mediaFeatures['for-mobile-and-small-tablet']} { 
        top: 0
    }
`;
const stickyHeader = `
    position: sticky;
    z-index: 118;
    top: 0;
    background-color: ${colors.blueBg};
    @media only screen and ${mediaFeatures['for-mobile-and-small-tablet']} { 
        top: 0
    }
`;
const webNavCont = (isSticky) => `
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1260px;
    margin: 0 auto;
    padding: 0px 15px;
    background-color: ${isSticky ? colors.blueBg : 'unset'};
`;
const logoCont = `
    display: flex;
    justify-content: start;
    align-items: center;
    img {
        width: 48px;
		height: 48px;
    }
    span {
        margin-left: 15px;
        font-size: 21px;
        font-weight: 700;
        font-family: ${typography.shinyHeader5["font-family"]};
        color: ${colors.white};
    }
    @media screen and ${mediaFeatures['for-mobile-and-tablet']} { 
        span {
            font-size: 18.5px;
        }
    }
`;
const navItemCont = `
    display:flex;
    align-items:center;
    justify-content: space-between;
`;
const HideWebNav = `
    @media screen and ${mediaFeatures['for-mobile-and-tablet']} { 
		display: none;
	}
`;
const showMobileNav = `
    @media screen and ${mediaFeatures['for-mobile-and-tablet']} { 
		display: block;
	}
`;
const navItem = (activeColor) => `
	padding: 22px 9px;
	margin: 0 15px;
	display: flex;
	justify-content: center;
	align-items: center;
    border-bottom: 1px solid ${activeColor ? colors.golden : 'transparent'};
	background: ${activeColor ? colors.blackShadow : 'unset'};
	p {
		font-size: 13px;
		color: ${colors.white};
		font-weight: 600;
	}
`;
const mobileNavItemCont = `
    display:flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    padding:0 15px;
    background-color: ${colors.blueBg};
`;
const mobileNavItem = (activeColor) => `
    display: flex;
    align-items: center;
    height: 38px;
    padding: 8px;
    background: ${colors.blackShadow};
    border-radius: 6px;
    color: white;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    cursor: pointer;
    border: 1px solid ${activeColor ? colors.golden : 'transparent'};
    transition: box-shadow .3s;
    margin:7px 0; 
    justify-content:center;
    p {
        width: max-content;
    }
`;
const horizontalLine = `
	height: 1px;
	width:100%;
	background: ${colors["border-grey"]};
	margin: 8px 0;
`;
