import { fetchFavouriteStats, fetchAPIData, fetchPopularSearchData, fetchRecentSearchData, fetchUserSearchData, fetchArticleData, fetchCitiesData, fetchUserData, fetchCartData, fetchEventData, fetchUserBuyTogetherDataFromApi, fetchFavouritesData, fetchArtistData, fetchClubData, fetchCityArtistsData, fetchAllArtistsData, fetchPartnerArticles, fetchVenueData, fetchCityVenuesData, fetchAllVenuesData, fetchTagData, fetchGroupsCategoriesData, updateCart, updateItemsInCart, reprocessCart, applyDiscountCoupon as applyDiscountCouponApiCall, removeDiscountCoupon as removeDiscountCouponApiCall, updateDeliveryDetails, clearDeliveryDetails as clearDeliveryDetailsApiCall, fetchPaymentSuccessData, fetchTicketsData, fetchTicketsShortcode, fetchStashesData, enableCancellationProtect as enableCancellationProtectApiCall, disableCancellationProtect as disableCancellationProtectApiCall, clearCartData, loginWithPaytmApi, fetchMonumentsData, fetchActiveOffers, resetCartTimer as resetCartTimerApiCall, fetchPaymentStatusData, fetchStreamToken, fetchFeedData, checkTicketPurchase, fetchRelatedEventsData, fetchStashBookData, fetchEventAdBannerData, fetchMatchScheduleEvents, fetchConfigByName, fetchDynamicEvents, fetchNewYearEvents, fetchNewYearOffersData, fetchDynamicPageData, fetchDynamicScheduleData, fetchIPLMatchScheduleEvents, isSamsungWalletSupported } from "../helpers/apiClient";
import { getFromCache, setInCache } from "../helpers/localPersistentCache";
import { Map, List, fromJS } from "immutable";
import { getAllRecentlyViewedWithResourcesOnly, getRehydratedStatus } from "../selectors/recentlyViewed";
import { areRemindersRehydrated, getReminderPhoneAndResources } from "../selectors/reminders";
import queryString from "query-string";
import { syncUserData } from "../helpers/userDataSync/syncDataHelpers/express";
import { checkPhoenixViaUserAgent, getUserDetailsForApp, loginForApp } from "../helpers/appBridge";
import { HomePageGroupCarouselConstants } from "../helpers/constants";
import { getElpMasterList } from "../selectors/elp";
import { getSelectedCityName } from "../selectors";
import { getDeviceName, getOS } from "../helpers/h5";
// Begin action types //
// generic actions
export const REQUEST_API_DATA = "REQUEST_API_DATA";
export const RECEIVE_API_DATA = "RECEIVE_API_DATA";
export const ERROR_API_DATA = "ERROR_API_DATA";
// homepage actions
export const RECEIVE_HOMEPAGE_DATA = "RECEIVE_HOMEPAGE_DATA";
export const REQUEST_HOMEPAGE_DATA = "REQUEST_HOMEPAGE_DATA";
export const CLEAR_HOMEPAGE_DATA = "CLEAR_HOMEPAGE_DATA";
export const RECEIVE_POPULAR_DATA = "RECEIVE_POPULAR_DATA";
export const REQUEST_POPULAR_DATA = "REQUEST_POPULAR_DATA";
export const CLEAR_POPULAR_DATA = "CLEAR_POPULAR_DATA";
export const REQUEST_STAYIN_ARTICLE_DATA = "REQUEST_STAYIN_ARTICLE_DATA";
export const RECEIVE_STAYIN_ARTICLE_DATA = "RECEIVE_STAYIN_ARTICLE_DATA";
// ELP actions
export const RECEIVE_ELP_DATA = "RECEIVE_ELP_DATA";
export const REQUEST_ELP_DATA = "REQUEST_ELP_DATA";
export const CLEAR_ELP_DATA = "CLEAR_ELP_DATA";
export const REQUEST_GOOUT_ELP_DATA = "REQUEST_GOOUT_ELP_DATA";
export const RECEIVE_GOOUT_ELP_DATA = "RECEIVE_GOOUT_ELP_DATA";
// goout actions
export const RECEIVE_GOOUT_DATA = "RECEIVE_GOOUT_DATA";
export const REQUEST_GOOUT_DATA = "REQUEST_GOOUT_DATA";
export const ERROR_GOOUT_DATA = "ERROR_GOOUT_DATA";
export const CLEAR_GOOUT_DATA = "CLEAR_GOOUT_DATA";
export const APPLY_GOOUT_SORT_FILTERS = "APPLY_GOOUT_SORT_FILTERS";
// stayin actions
export const RECEIVE_STAYIN_DATA = "RECEIVE_STAYIN_DATA";
export const REQUEST_STAYIN_DATA = "REQUEST_STAYIN_DATA";
export const CLEAR_STAYIN_DATA = "CLEAR_STAYIN_DATA";
export const APPLY_STAYIN_SORT_FILTERS = "APPLY_STAYIN_SORT_FILTERS";
// dynamic home section actions
export const RECEIVE_DYNAMIC_SECTION_DATA = "RECEIVE_DYNAMIC_SECTION_DATA";
export const REQUEST_DYNAMIC_SECTION_DATA = "REQUEST_DYNAMIC_SECTION_DATA";
export const ERROR_DYNAMIC_SECTION_DATA = "ERROR_DYNAMIC_SECTION_DATA";
// UI actions
export const CLEAR_SITE_DISPLAY_MODE = "CLEAR_SITE_DISPLAY_MODE";
export const SET_PLATFORM = "SET_PLATFORM";
export const SET_SEARCH_BAR_IN_VIEW = "SET_SEARCH_BAR_IN_VIEW";
export const SET_SEARCH_TAB = "SET_SEARCH_TAB";
export const SET_SPA_MODE = "SET_SPA_MODE";
export const SHOW_CITY_SELECTOR = "SHOW_CITY_SELECTOR";
export const SITE_DISPLAY_MODE_CHANGED = "SITE_DISPLAY_MODE_CHANGED";
export const TAB_SELECTED = "TAB_SELECTED";
// Article actions
export const REQUEST_ARTICLE_DATA = "REQUEST_ARTICLE_DATA";
export const RECEIVE_ARTICLE_DATA = "RECEIVE_ARTICLE_DATA";
export const CLEAR_ARTICLE_DATA = "CLEAR_ARTICLE_DATA";
// Event actions
export const REQUEST_EVENT_DATA = "REQUEST_EVENT_DATA";
export const RECEIVE_EVENT_DATA = "RECEIVE_EVENT_DATA";
export const CLEAR_EVENT_DATA = "CLEAR_EVENT_DATA";
//Fav Stats actions
export const REQUEST_EVENT_FAV = "REQUEST_EVENT_FAV";
export const RECEIVE_EVENT_FAV = "RECEIVE_EVENT_FAV";
// Cities actions
export const REQUEST_CITIES_DATA = "REQUEST_CITIES_DATA";
export const RECEIVE_CITIES_DATA = "RECEIVE_CITIES_DATA";
export const USER_SELECTED_CITY = "USER_SELECTED_CITY";
export const SET_IS_SELECTED_CITY_INFERRED = "SET_IS_SELECTED_CITY_INFERRED";
// TOAST actions
export const RECEIVE_TOAST_NOTTIFICATIONS = "RECEIVE_TOAST_NOTTIFICATIONS";
export const CLEAR_TOAST_NOTIFICATIONS = "CLEAR_TOAST_NOTIFICATIONS";
export const RECEIVE_TOAST_MESSAGES = "RECEIVE_TOAST_MESSAGES";
// Modal actions
export const SET_MODAL_TYPE = "SET_MODAL_TYPE";
export const RESET_MODAL_TYPE = "RESET_MODAL_TYPE";
// Login Modal actions
export const SET_EMAIL = "SET_EMAIL";
export const SET_MODAL_SCREEN = "SET_MODAL_SCREEN";
export const SET_MODAL_LOGIN_ERROR = "SET_MODAL_LOGIN_ERROR";
export const SET_MODAL_REDIRECT_URL = "SET_MODAL_REDIRECT_URL";
export const SET_MODAL_SOURCE = "SET_MODAL_SOURCE";
export const CLEAR_LOGIN_MODAL_DATA = "CLEAR_LOGIN_MODAL_DATA";
// User actions
export const REQUEST_LOGIN_DATA = "REQUEST_LOGIN_DATA";
export const RECEIVE_LOGIN_DATA = "RECEIVE_LOGIN_DATA";
export const ERROR_LOGIN_DATA = "ERROR_LOGIN_DATA";
export const CLEAR_LOGIN_DATA = "CLEAR_LOGIN_DATA";
export const UNFOLLOW_PARTNER = "UNFOLLOW_PARTNER";
export const FOLLOW_PARTNER = "FOLLOW_PARTNER";
export const FAVOURITE_EVENT = "FAVOURITE_EVENT";
export const UNFAVOURITE_EVENT = "UNFAVOURITE_EVENT";
export const CLEAR_FAVOURITES_DATA = "CLEAR_FAVOURITES_DATA";
export const REQUEST_ACTIVE_FAVOURITES_DATA = "REQUEST_ACTIVE_FAVOURITES_DATA";
export const RECEIVE_ACTIVE_FAVOURITES_DATA = "RECEIVE_ACTIVE_FAVOURITES_DATA";
export const ERROR_ACTIVE_FAVOURITES_DATA = "ERROR_ACTIVE_FAVOURITES_DATA";
export const REQUEST_PAST_FAVOURITES_DATA = "REQUEST_PAST_FAVOURITES_DATA";
export const RECEIVE_PAST_FAVOURITES_DATA = "RECEIVE_PAST_FAVOURITES_DATA";
export const ERROR_PAST_FAVOURITES_DATA = "ERROR_PAST_FAVOURITES_DATA";
export const IS_CITY_CHANGED = "IS_CITY_CHANGED";
// Cart actions
export const REQUEST_CART_DATA = "REQUEST_CART_DATA";
export const RECEIVE_CART_DATA = "RECEIVE_CART_DATA";
export const ERROR_CART_DATA = "ERROR_CART_DATA";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const REMOVE_CART_ITEM_SUCCESS = "REMOVE_CART_ITEM_SUCCESS";
export const REMOVE_CART_ITEM_ERROR = "REMOVE_CART_ITEM_ERROR";
export const CLEAR_CART = "CLEAR_CART";
export const CLEAR_CART_SUCCESS = "CLEAR_CART_SUCCESS";
export const CLEAR_CART_ERROR = "CLEAR_CART_ERROR";
export const RESET_CART_TIMER = "RESET_CART_TIMER";
export const RESET_CART_TIMER_SUCCESS = "RESET_CART_TIMER_SUCCESS";
export const RESET_CART_TIMER_ERROR = "RESET_CART_TIMER_ERROR";
export const APPLY_DISCOUNT_COUPON = "APPLY_DISCOUNT_COUPON";
export const APPLY_DISCOUNT_COUPON_SUCCESS = "APPLY_DISCOUNT_COUPON_SUCCESS";
export const APPLY_DISCOUNT_COUPON_ERROR = "APPLY_DISCOUNT_COUPON_ERROR";
export const REMOVE_DISCOUNT_COUPON = "REMOVE_DISCOUNT_COUPON";
export const REMOVE_DISCOUNT_COUPON_SUCCESS = "REMOVE_DISCOUNT_COUPON_SUCCESS";
export const REMOVE_DISCOUNT_COUPON_ERROR = "REMOVE_DISCOUNT_COUPON_ERROR";
export const SET_DELIVERY_DETAILS = "SET_DELIVERY_DETAILS";
export const SET_DELIVERY_DETAILS_SUCCESS = "SET_DELIVERY_DETAILS_SUCCESS";
export const SET_DELIVERY_DETAILS_ERROR = "SET_DELIVERY_DETAILS_ERROR";
export const CLEAR_DELIVERY_DETAILS = "CLEAR_DELIVERY_DETAILS";
export const CLEAR_DELIVERY_DETAILS_SUCCESS = "CLEAR_DELIVERY_DETAILS_SUCCESS";
export const CLEAR_DELIVERY_DETAILS_ERROR = "CLEAR_DELIVERY_DETAILS_ERROR";
export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const ADD_ITEM_TO_CART_SUCCESS = "ADD_ITEM_TO_CART_SUCCESS";
export const ADD_ITEM_TO_CART_ERROR = "ADD_ITEM_TO_CART_ERROR";
export const CLEAR_ADD_ITEM_TO_CART_ERROR = "CLEAR_ADD_ITEM_TO_CART_ERROR";
export const ENABLE_CANCELLATION_PROTECT = "ENABLE_CANCELLATION_PROTECT";
export const ENABLE_CANCELLATION_PROTECT_SUCCESS = "ENABLE_CANCELLATION_PROTECT_SUCCESS";
export const ENABLE_CANCELLATION_PROTECT_ERROR = "ENABLE_CANCELLATION_PROTECT_ERROR";
export const DISABLE_CANCELLATION_PROTECT = "DISABLE_CANCELLATION_PROTECT";
export const DISABLE_CANCELLATION_PROTECT_SUCCESS = "DISABLE_CANCELLATION_PROTECT_SUCCESS";
export const DISABLE_CANCELLATION_PROTECT_ERROR = "DISABLE_CANCELLATION_PROTECT_ERROR";
// Buy-Together User Actions
export const REQUEST_BTG_DATA = "REQUEST_BTG_DATA";
export const RECEIVE_BTG_DATA = "RECEIVE_BTG_DATA";
export const ERROR_BTG_DATA = "ERROR_BTG_DATA";
// search actions
export const REQUEST_POPULAR_SEARCH_DATA = "REQUEST_POPULAR_SEARCH_DATA";
export const RECEIVE_POPULAR_SEARCH_DATA = "RECEIVE_POPULAR_SEARCH_DATA";
export const ERROR_POPULAR_SEARCH_DATA = "ERROR_POPULAR_SEARCH_DATA";
export const REQUEST_RECENT_SEARCH_DATA = "REQUEST_RECENT_SEARCH_DATA";
export const RECEIVE_RECENT_SEARCH_DATA = "RECEIVE_RECENT_SEARCH_DATA";
export const ERROR_RECENT_SEARCH_DATA = "ERROR_RECENT_SEARCH_DATA";
export const REQUEST_USER_SEARCH_DATA = "REQUEST_USER_SEARCH_DATA";
export const RECEIVE_USER_SEARCH_DATA = "RECEIVE_USER_SEARCH_DATA";
export const ERROR_USER_SEARCH_DATA = "ERROR_USER_SEARCH_DATA";
export const CLEAR_USER_SEARCH_DATA = "CLEAR_USER_SEARCH_DATA";
export const REQUEST_ALGOLIA_SEARCH_DATA = "REQUEST_ALGOLIA_SEARCH_DATA";
export const RECEIVE_ALGOLIA_SEARCH_DATA = "RECEIVE_ALGOLIA_SEARCH_DATA";
export const ERROR_ALGOLIA_SEARCH_DATA = "ERROR_ALGOLIA_SEARCH_DATA";
export const UPDATE_ALGOLIA_INDEX_DATA = "UPDATE_ALGOLIA_INDEX_DATA";
export const CLEAR_ALGOLIA_SEARCH_DATA = "CLEAR_ALGOLIA_SEARCH_DATA";
export const RECEIVE_QUICK_SEARCH_FILTER = "RECEIVE_QUICK_SEARCH_FILTER";
export const ERROR_QUICK_SEARCH_FILTER = "ERROR_QUICK_SEARCH_FILTER";
// groups
export const SET_GROUPS_DATA = "SET_GROUPS_DATA";
// artists
export const REQUEST_ARTIST_DATA = "REQUEST_ARTIST_DATA";
export const RECEIVE_ARTIST_DATA = "RECEIVE_ARTIST_DATA";
export const REQUEST_ARTISTS_DATA = "REQUEST_ARTISTS_DATA";
export const RECEIVE_ARTISTS_DATA = "RECEIVE_ARTISTS_DATA";
export const REQUEST_ALL_ARTISTS_DATA = "REQUEST_ALL_ARTISTS_DATA";
export const RECEIVE_ALL_ARTISTS_DATA = "RECEIVE_ALL_ARTISTS_DATA";
export const CLEAR_ALL_ARTISTS_DATA = "CLEAR_ALL_ARTISTS_DATA";
export const CLEAR_ARTIST_DATA = "CLEAR_ARTIST_DATA";
// venues
export const REQUEST_VENUE_DATA = "REQUEST_VENUE_DATA";
export const RECEIVE_VENUE_DATA = "RECEIVE_VENUE_DATA";
export const REQUEST_VENUES_DATA = "REQUEST_VENUES_DATA";
export const RECEIVE_VENUES_DATA = "RECEIVE_VENUES_DATA";
export const REQUEST_ALL_VENUES_DATA = "REQUEST_ALL_VENUES_DATA";
export const RECEIVE_ALL_VENUES_DATA = "RECEIVE_ALL_VENUES_DATA";
export const CLEAR_ALL_VENUES_DATA = "CLEAR_ALL_VENUES_DATA";
export const CLEAR_VENUE_DATA = "CLEAR_VENUE_DATA";
// clubs
export const REQUEST_CLUB_DATA = "REQUEST_CLUB_DATA";
export const RECEIVE_CLUB_DATA = "RECEIVE_CLUB_DATA";
export const CLEAR_CLUB_DATA = "CLEAR_CLUB_DATA";
// partners (combined apis/actions)
export const REQUEST_PARTNER_UPDATES = "REQUEST_PARTNER_UPDATES";
export const RECEIVE_PARTNER_UPDATES = "RECEIVE_PARTNER_UPDATES";
// groups and categories
export const REQUEST_GROUPS_CATEGORIES_DATA = "REQUEST_GROUPS_CATEGORIES_DATA";
export const RECEIVE_GROUPS_CATEGORIES_DATA = "RECEIVE_GROUPS_CATEGORIES_DATA";
// explore menu
export const TOGGLE_EXPLORE_MENU = "TOGGLE_EXPLORE_MENU";
// mobile menu
export const TOGGLE_MOBILE_MENU = "TOGGLE_MOBILE_MENU";
// Fetch tag display information
export const REQUEST_MODEL_DATA_FOR_TAG = "REQUEST_MODEL_DATA_FOR_TAG";
export const RECEIVE_MODEL_DATA_FOR_TAG = "RECEIVE_MODEL_DATA_FOR_TAG";
export const ERROR_MODEL_DATA_FOR_TAG = "ERROR_MODEL_DATA_FOR_TAG";
// clear tag information
export const CLEAR_MODEL_DATA_FOR_TAG = "CLEAR_MODEL_DATA_FOR_TAG";
// Summer campaign actions
export const NAVIGATED_TO_SUMMER_LANDING_PAGE = "NAVIGATED_TO_SUMMER_LANDING_PAGE";
export const REQUEST_PAYMENT_SUCCESS_DATA = "REQUEST_PAYMENT_SUCCESS_DATA";
export const RECEIVE_PAYMENT_SUCCESS_DATA = "RECEIVE_PAYMENT_SUCCESS_DATA";
export const CLEAR_PAYMENT_SUCCESS_DATA = "CLEAR_PAYMENT_SUCCESS_DATA";
export const REQUEST_PAYMENT_STATUS_DATA = "REQUEST_PAYMENT_STATUS_DATA";
export const RECEIVE_PAYMENT_STATUS_DATA = "RECEIVE_PAYMENT_STATUS_DATA";
export const REQUEST_TICKETS_DATA = "REQUEST_TICKETS_DATA";
export const RECEIVE_TICKETS_DATA = "RECEIVE_TICKETS_DATA";
export const CLEAR_TICKETS_DATA = "CLEAR_TICKETS_DATA";
export const ERROR_TICKETS_DATA = "ERROR_TICKETS_DATA";
export const REQUEST_MORE_TICKETS_DATA = "REQUEST_MORE_TICKETS_DATA";
export const RECEIVE_MORE_TICKETS_DATA = "RECEIVE_MORE_TICKETS_DATA";
export const ERROR_MORE_TICKETS_DATA = "ERROR_MORE_TICKETS_DATA";
export const REQUEST_TICKETS_FROM_SHORTCODE = "REQUEST_TICKETS_FROM_SHORTCODE";
export const RECEIVE_TICKETS_FROM_SHORTCODE = "RECEIVE_TICKETS_FROM_SHORTCODE";
export const REQUEST_STASHES_DATA = "REQUEST_STASHES_DATA";
export const RECEIVE_STASHES_DATA = "RECEIVE_STASHES_DATA";
export const CLEAR_STASHES_DATA = "CLEAR_STASHES_DATA";
export const REQUEST_STASHBOOK_DATA = "REQUEST_STASHBOOK_DATA";
export const RECEIVE_STASHBOOK_DATA = "RECEIVE_STASHBOOK_DATA";
// User behaviour actions
export const ADD_TO_RECENTLY_VIEWED = "ADD_TO_RECENTLY_VIEWED";
export const REHYDRATE_RECENTLY_VIEWED = "REHYDRATE_RECENTLY_VIEWED";
// Monuments actions
export const REQUEST_MONUMENTS_DATA = "REQUEST_MONUMENTS_DATA";
export const RECEIVE_MONUMENTS_DATA = "RECEIVE_MONUMENTS_DATA";
// Discount Offer actions for
export const REQUEST_DISCOUNT_OFFERS = "REQUEST_DISCOUNT_OFFERS";
export const RECEIVE_DISCOUNT_OFFERS = "RECEIVE_DISCOUNT_OFFERS";
export const ERROR_DISCOUNT_OFFERS = "ERROR_DISCOUNT_OFFERS";
//Event reminder actions
export const ADD_REMINDER = "ADD_REMINDER";
export const REHYDRATE_REMINDERS = "REHYDRATE_REMINDERS";
export const ADD_REMINDER_ERRORED = "ADD_REMINDER_ERRORED";
export const REHYDRATE_REMINDERS_ERRORED = "REHYDRATE_REMINDERS_ERRORED";
export const REQUEST_USER_TOKEN = "REQUEST_USER_TOKEN";
export const RECEIVE_USER_TOKEN = "RECEIVE_USER_TOKEN";
export const REQUEST_ACTIVITY_DATA = "REQUEST_ACTIVITY_DATA";
export const RECEIVE_ACTIVITY_DATA = "RECEIVE_ACTIVITY_DATA";
export const REQUEST_TICKET_PURCHASE_STATUS = "REQUEST_TICKET_PURCHASE_STATUS";
export const RECEIVE_TICKET_PURCHASE_STATUS = "RECEIVE_TICKET_PURCHASE_STATUS";
export const SET_REPORT_EVENT_DATA = "SET_REPORT_EVENT_DATA";
export const REHYDRATE_REPORT_EVENT_DATA = "REHYDRATE_REPORT_EVENT_DATA";
export const CLEAR_REPORT_EVENT_DATA = "CLEAR_REPORT_EVENT_DATA";
export const REQUEST_RELATED_EVENTS_DATA = "REQUEST_RELATED_EVENTS_DATA";
export const RECEIVE_RELATED_EVENTS_DATA = "RECEIVE_RELATED_EVENTS_DATA";
export const REQUEST_EVENT_AD_BANNER_DATA = "REQUEST_EVENT_AD_BANNER_DATA";
export const RECEIVE_EVENT_AD_BANNER_DATA = "RECEIVE_EVENT_AD_BANNER_DATA";
export const TOGGLE_BODY_SCROLL = "TOGGLE_BODY_SCROLL";
export const SET_SOCIAL_LOGIN_FLAG = "SET_SOCIAL_LOGIN_FLAG";
export const SET_SEARCH_FILTER_APPLIED = "SET_SEARCH_FILTER_APPLIED";
// ISL Actions
// match schedule events
export const REQUEST_MATCH_SCHEDULE_EVENTS_DATA = "REQUEST_MATCH_SCHEDULE_EVENTS_DATA";
export const RECEIVE_MATCH_SCHEDULE_EVENTS_DATA = "RECEIVE_MATCH_SCHEDULE_EVENTS_DATA";
export const ERROR_MATCH_SCHEDULE_EVENTS_DATA = "ERROR_MATCH_SCHEDULE_EVENTS_DATA";
// IPL Actions
// match schedule events
export const REQUEST_IPL_MATCH_SCHEDULE_EVENTS_DATA = "REQUEST_IPL_MATCH_SCHEDULE_EVENTS_DATA";
export const RECEIVE_IPL_MATCH_SCHEDULE_EVENTS_DATA = "RECEIVE_IPL_MATCH_SCHEDULE_EVENTS_DATA";
export const ERROR_IPL_MATCH_SCHEDULE_EVENTS_DATA = "ERROR_IPL_MATCH_SCHEDULE_EVENTS_DATA";
// dynamic landing page events
export const REQUEST_DYNAMIC_LANDING_PAGE_EVENTS_DATA = "REQUEST_DYNAMIC_LANDING_PAGE_EVENTS_DATA";
export const RECEIVE_DYNAMIC_LANDING_PAGE_EVENTS_DATA = "RECEIVE_DYNAMIC_LANDING_PAGE_EVENTS_DATA";
export const ERROR_DYNAMIC_LANDING_PAGE_EVENTS_DATA = "ERROR_DYNAMIC_LANDING_PAGE_EVENTS_DATA";
// new year  landing page events
export const REQUEST_NEWYEAR_LANDING_PAGE_EVENTS_DATA = "REQUEST_NEWYEAR_LANDING_PAGE_EVENTS_DATA";
export const RECEIVE_NEWYEAR_LANDING_PAGE_EVENTS_DATA = "RECEIVE_NEWYEAR_LANDING_PAGE_EVENTS_DATA";
export const ERROR_NEWYEAR_LANDING_PAGE_EVENTS_DATA = "ERROR_NEWYEAR_LANDING_PAGE_EVENTS_DATA";
export const REQUEST_NEWYEAR_OFFER_DATA = "REQUEST_NEWYEAR_OFFER_DATA";
export const RECEIVE_NEWYEAR_OFFER_DATA = "RECEIVE_NEWYEAR_OFFER_DATA";
export const ERROR_NEWYEAR_OFFER_DATA = "ERROR_NEWYEAR_OFFER_DATA";
export const REQUEST_MUMBAI_FEST_EVENTS_DATA = "REQUEST_MUMBAI_FEST_EVENTS_DATA";
export const RECEIVE_MUMBAI_FEST_EVENTS_DATA = "RECEIVE_MUMBAI_FEST_EVENTS_DATA";
export const ERROR_MUMBAI_FEST_EVENTS_DATA = "ERROR_MUMBAI_FEST_EVENTS_DATA";
export const REQUEST_HOLI24_OFFER_DATA = "REQUEST_HOLI24_OFFER_DATA";
export const RECEIVE_HOLI24_OFFER_DATA = "RECEIVE_HOLI24_OFFER_DATA";
export const ERROR_HOLI24_OFFER_DATA = "ERROR_HOLI24_OFFER_DATA";
//* Microsite page data
export const REQUEST_MICROSITE_LANDING_PAGE_SCHEDULE_DATA = "REQUEST_MICROSITE_LANDING_PAGE_SCHEDULE_DATA";
export const RECEIVE_MICROSITE_LANDING_PAGE_SCHEDULE_DATA = "RECEIVE_MICROSITE_LANDING_PAGE_SCHEDULE_DATA";
export const ERROR_MICROSITE_LANDING_PAGE_SCHEDULE_DATA = "ERROR_MICROSITE_LANDING_PAGE_SCHEDULE_DATA";
export const REQUEST_MICROSITE_LANDING_PAGE_DATA = "REQUEST_MICROSITE_LANDING_PAGE_DATA";
export const RECEIVE_MICROSITE_LANDING_PAGE_DATA = "RECEIVE_MICROSITE_LANDING_PAGE_DATA";
export const ERROR_MICROSITE_LANDING_PAGE_DATA = "ERROR_MICROSITE_LANDING_PAGE_DATA";
export const IS_SAMSUNG_WALLET_SUPPORTED = "IS_SAMSUNG_WALLET_SUPPORTED";
// End action types //
// Begin action creators //
// sync creators
export function toggleCitySelector(shouldShowCitySelector) {
    return {
        type: SHOW_CITY_SELECTOR,
        payload: shouldShowCitySelector
    };
}
export function updateSiteDisplayMode(newDisplayMode) {
    return {
        type: SITE_DISPLAY_MODE_CHANGED,
        payload: newDisplayMode
    };
}
export function clearSiteDisplayMode() {
    return {
        type: CLEAR_SITE_DISPLAY_MODE
    };
}
export function setSearchBarInView(bool) {
    return {
        type: SET_SEARCH_BAR_IN_VIEW,
        payload: bool
    };
}
export function updateSelectedTab(displayMode, selectedTabName) {
    return {
        type: TAB_SELECTED,
        payload: {
            displayMode,
            selectedTabName: selectedTabName || "" // if selectedTabName is sent as undefined
        }
    };
}
export function setSearchTab(index) {
    return {
        type: SET_SEARCH_TAB,
        payload: index
    };
}
export function clearEventData() {
    return {
        type: CLEAR_EVENT_DATA
    };
}
export function clearArticleData(articleSlug) {
    return {
        type: CLEAR_ARTICLE_DATA,
        payload: articleSlug
    };
}
export function clearHomepageData() {
    return {
        type: CLEAR_HOMEPAGE_DATA
    };
}
export function clearGoOutData() {
    return {
        type: CLEAR_GOOUT_DATA
    };
}
export function clearStayInData() {
    return {
        type: CLEAR_STAYIN_DATA,
        payload: null
    };
}
export function setUserLoginData(userInfo) {
    return {
        type: RECEIVE_LOGIN_DATA,
        payload: userInfo
    };
}
export function unFollowPartner(type, _id) {
    return {
        type: UNFOLLOW_PARTNER,
        payload: { type, _id }
    };
}
export function followPartner(type, _id) {
    return {
        type: FOLLOW_PARTNER,
        payload: { type, _id }
    };
}
export function favouriteEvent(event) {
    return {
        type: FAVOURITE_EVENT,
        payload: { event }
    };
}
export function unfavouriteEvent(slug) {
    return {
        type: UNFAVOURITE_EVENT,
        payload: { slug }
    };
}
export function setCities(payload) {
    return {
        type: RECEIVE_CITIES_DATA,
        payload
    };
}
export function updateSelectedCity(newSelectedCity) {
    return {
        type: USER_SELECTED_CITY,
        payload: newSelectedCity
    };
}
// selected city is inferred if the user hasnt chosen it herself
// but we have inferred it from the url
export function setIsSelectedCityInferred(payload) {
    return {
        type: SET_IS_SELECTED_CITY_INFERRED,
        payload
    };
}
export function clearUserSearchData() {
    return {
        type: CLEAR_USER_SEARCH_DATA
    };
}
export function clearAlgoliaSearchData() {
    return {
        type: CLEAR_ALGOLIA_SEARCH_DATA
    };
}
export function clearLoginData() {
    return {
        type: CLEAR_LOGIN_DATA
    };
}
// sort and filter go-out and stay-in sections
export function applyGooutSortFilters(tab, filters, sort, componentType) {
    return {
        type: APPLY_GOOUT_SORT_FILTERS,
        tab,
        filters,
        sort,
        componentType
    };
}
export function applyStayinSortFilters(tab, filters, sort) {
    return {
        type: APPLY_STAYIN_SORT_FILTERS,
        tab,
        filters,
        sort
    };
}
export function setPlatform(platform) {
    return {
        type: SET_PLATFORM,
        payload: platform
    };
}
export function setSPAMode(isSPA) {
    return {
        type: SET_SPA_MODE,
        payload: isSPA
    };
}
export function receiveGroupData(data) {
    return {
        type: SET_GROUPS_DATA,
        payload: data
    };
}
export function toggleExploreMenu() {
    return {
        type: TOGGLE_EXPLORE_MENU
    };
}
export function toggleMobileMenu() {
    return {
        type: TOGGLE_MOBILE_MENU
    };
}
export function navigatedToSummerLandingPage(isSummerLandingPage) {
    return {
        type: NAVIGATED_TO_SUMMER_LANDING_PAGE,
        payload: isSummerLandingPage
    };
}
export function receiveGameEvent(eventId) {
    return {
        type: RECEIVE_GAME_EVENT,
        payload: { eventId, gameEvent: { value: { count: 0 } } }
    };
}
// async creators
// generate api resource
function homeAPIResource(filter, city) {
    return `home?filterBy=${filter}${city ? `&city=${city}` : ""}`;
}
// get normalised home page data
function homeAPIResourceNormalise(filter, city, banner_id, select, slug, entity, type) {
    const query = {
        norm: 1,
        filterBy: filter,
        banner_id,
        city
    };
    if (["tag", "brand"].includes(entity)) {
        query[`${entity}Slug`] = slug;
    }
    if (select) {
        query["select"] = select;
    }
    if (city && city.toLowerCase() !== "online") {
        query["eventType"] = type;
    }
    query["typeFilter"] = city === "online" ? "online" : "physical";
    return `home?${queryString.stringify(query)}`;
}
/**
 * Fetches the homepage resources for a city
 * @param {string} city
 * @return {{types: [string], promise: Promise.<U>}}
 */
export function fetchHomepageResources(city) {
    const HOME_API_RESOURCE = `home/featured${city ? `?city=${city}` : ""}`;
    return {
        types: [REQUEST_HOMEPAGE_DATA, RECEIVE_HOMEPAGE_DATA, ERROR_API_DATA],
        promise: fetchAPIData(HOME_API_RESOURCE)
    };
}
export function fetchPopularData(city, eventType = "physical") {
    const HOME_API_RESOURCE = homeAPIResourceNormalise("go-out", city, null, "popular", null, null, eventType);
    return {
        types: [REQUEST_POPULAR_DATA, RECEIVE_POPULAR_DATA, ERROR_API_DATA],
        promise: fetchAPIData(HOME_API_RESOURCE)
    };
}
export function fetchGooutResources(city, banner_id, eventType = "physical") {
    const HOME_API_RESOURCE = homeAPIResourceNormalise("go-out", city, banner_id, null, null, null, eventType);
    return {
        types: [REQUEST_GOOUT_DATA, RECEIVE_GOOUT_DATA, ERROR_GOOUT_DATA],
        promise: fetchAPIData(HOME_API_RESOURCE)
    };
}
export function fetchGooutHomeResources(city, banner_id, device) {
    const { enabled: groupEnabled, groupName } = HomePageGroupCarouselConstants;
    let homeAPIPath = `home/get-homepage-data?city=${city}`;
    if (banner_id) {
        homeAPIPath = `${homeAPIPath}&banner_id=${banner_id}`;
    }
    if (groupEnabled) {
        homeAPIPath = `${homeAPIPath}&group=${groupName}`;
    }
    if (device) {
        homeAPIPath = `${homeAPIPath}&device=${device}`;
    }
    return {
        types: [REQUEST_GOOUT_DATA, RECEIVE_GOOUT_DATA, ERROR_GOOUT_DATA],
        promise: fetchAPIData(homeAPIPath)
    };
}
export function fetchGooutELPResourcesWithoutPopular(city, banner_id, queryTag, eventType = "physical") {
    const HOME_API_RESOURCE = homeAPIResourceNormalise("go-out", city, banner_id, "lite", queryTag, null, eventType);
    return {
        types: [REQUEST_GOOUT_ELP_DATA, RECEIVE_GOOUT_ELP_DATA, ERROR_GOOUT_DATA],
        promise: fetchAPIData(HOME_API_RESOURCE)
    };
}
export function fetchDynamicELPSectionDataWithoutPopular(queryTag, entity, eventType = "physical") {
    if (!queryTag)
        return;
    const HOME_API_RESOURCE = homeAPIResourceNormalise("go-out", null, null, "lite", queryTag, entity, eventType);
    return {
        types: [REQUEST_DYNAMIC_SECTION_DATA, RECEIVE_DYNAMIC_SECTION_DATA, ERROR_DYNAMIC_SECTION_DATA],
        promise: fetchAPIData(HOME_API_RESOURCE),
        meta: queryTag
    };
}
export function fetchStayinResources(city) {
    const HOME_API_RESOURCE = homeAPIResource("stay-in", city);
    return {
        types: [REQUEST_STAYIN_DATA, RECEIVE_STAYIN_DATA, ERROR_API_DATA],
        promise: fetchAPIData(HOME_API_RESOURCE)
    };
}
export function fetchPopularSearch(city) {
    return {
        types: [REQUEST_POPULAR_SEARCH_DATA, RECEIVE_POPULAR_SEARCH_DATA, ERROR_POPULAR_SEARCH_DATA],
        promise: fetchPopularSearchData(city)
    };
}
export function fetchRecentSearch(city) {
    return {
        types: [REQUEST_RECENT_SEARCH_DATA, RECEIVE_RECENT_SEARCH_DATA, ERROR_RECENT_SEARCH_DATA],
        promise: fetchRecentSearchData(city)
    };
}
export function fetchUserSearch(query) {
    return {
        types: [REQUEST_USER_SEARCH_DATA, RECEIVE_USER_SEARCH_DATA, ERROR_USER_SEARCH_DATA],
        promise: fetchUserSearchData(query)
    };
}
export function updateAlgoliaIndexData({ type, hits, queryID }) {
    return {
        type: UPDATE_ALGOLIA_INDEX_DATA,
        payload: {
            type,
            hits,
            queryID
        }
    };
}
export function fetchFavouriteStatsById({ eventId, slug }) {
    return {
        types: [REQUEST_EVENT_FAV, RECEIVE_EVENT_FAV, ERROR_API_DATA],
        promise: fetchFavouriteStats({ eventId }),
        meta: slug
    };
}
export function fetchEventDetails({ slug }) {
    return {
        types: [REQUEST_EVENT_DATA, RECEIVE_EVENT_DATA, ERROR_API_DATA],
        promise: fetchEventData({ slug }),
        meta: { slug }
    };
}
export function fetchUserToken(eventId, isLoggedIn) {
    return {
        types: [REQUEST_USER_TOKEN, RECEIVE_USER_TOKEN, ERROR_API_DATA],
        promise: fetchStreamToken(eventId, isLoggedIn)
    };
}
export function fetchFeedsActivityData(data, isLoggedIn, slug) {
    return {
        types: [REQUEST_ACTIVITY_DATA, RECEIVE_ACTIVITY_DATA, ERROR_API_DATA],
        promise: fetchFeedData(data, isLoggedIn),
        meta: { slug }
    };
}
export function checkTicketPurchaseStatus(eventId) {
    return {
        types: [REQUEST_TICKET_PURCHASE_STATUS, RECEIVE_TICKET_PURCHASE_STATUS, ERROR_API_DATA],
        promise: checkTicketPurchase(eventId)
    };
}
export function fetchMonuments() {
    return {
        types: [REQUEST_MONUMENTS_DATA, RECEIVE_MONUMENTS_DATA, ERROR_API_DATA],
        promise: fetchMonumentsData()
    };
}
export function fetchArticle({ slug }) {
    return {
        types: [REQUEST_ARTICLE_DATA, RECEIVE_ARTICLE_DATA, ERROR_API_DATA],
        promise: fetchArticleData({ slug }),
        meta: { slug }
    };
}
export function fetchArtist({ slug }) {
    return {
        types: [REQUEST_ARTIST_DATA, RECEIVE_ARTIST_DATA, ERROR_API_DATA],
        promise: fetchArtistData({ slug })
    };
}
export function setArtistData(payload) {
    return {
        type: RECEIVE_ARTIST_DATA,
        payload
    };
}
export function fetchCityArtists(city) {
    return {
        types: [REQUEST_ARTISTS_DATA, RECEIVE_ARTISTS_DATA, ERROR_API_DATA],
        promise: fetchCityArtistsData(city)
    };
}
export function fetchAllArtists() {
    return {
        types: [REQUEST_ALL_ARTISTS_DATA, RECEIVE_ALL_ARTISTS_DATA, ERROR_API_DATA],
        promise: fetchAllArtistsData()
    };
}
export function clearArtist({ slug }) {
    return {
        type: CLEAR_ARTIST_DATA,
        payload: { slug }
    };
}
export function clearAllArtists() {
    return {
        type: CLEAR_ALL_ARTISTS_DATA
    };
}
export function fetchPartnerUpdates({ slug }) {
    return {
        types: [REQUEST_PARTNER_UPDATES, RECEIVE_PARTNER_UPDATES, ERROR_API_DATA],
        promise: fetchPartnerArticles({ slug })
    };
}
export function fetchVenue({ slug }) {
    return {
        types: [REQUEST_VENUE_DATA, RECEIVE_VENUE_DATA, ERROR_API_DATA],
        promise: fetchVenueData({ slug })
    };
}
export function setVenueData(payload) {
    return {
        type: RECEIVE_VENUE_DATA,
        payload
    };
}
export function fetchCityVenues(city) {
    return {
        types: [REQUEST_VENUES_DATA, RECEIVE_VENUES_DATA, ERROR_API_DATA],
        promise: fetchCityVenuesData(city)
    };
}
export function fetchAllVenues() {
    return {
        types: [REQUEST_ALL_VENUES_DATA, RECEIVE_ALL_VENUES_DATA, ERROR_API_DATA],
        promise: fetchAllVenuesData()
    };
}
export function clearVenue({ slug }) {
    return {
        type: CLEAR_VENUE_DATA,
        payload: { slug }
    };
}
export function clearAllVenues() {
    return {
        type: CLEAR_ALL_VENUES_DATA
    };
}
export function setEmail(payload) {
    return {
        type: SET_EMAIL,
        payload
    };
}
export function setModalScreen(payload) {
    return {
        type: SET_MODAL_SCREEN,
        payload
    };
}
export function setModalRedirectUrl(payload) {
    return {
        type: SET_MODAL_REDIRECT_URL,
        payload
    };
}
export function setModalSource(payload) {
    return {
        type: SET_MODAL_SOURCE,
        payload
    };
}
export function setModalType(payload) {
    return {
        type: SET_MODAL_TYPE,
        payload
    };
}
export function setLoginError(payload) {
    return {
        type: SET_MODAL_LOGIN_ERROR,
        payload
    };
}
export function clearLoginModalData() {
    return {
        type: CLEAR_LOGIN_MODAL_DATA
    };
}
export function resetModalType() {
    return {
        type: RESET_MODAL_TYPE
    };
}
export function setNotifications(payload) {
    return {
        type: RECEIVE_TOAST_NOTTIFICATIONS,
        payload
    };
}
export function clearNotifications() {
    return {
        type: CLEAR_TOAST_NOTIFICATIONS
    };
}
export function setToastMessages(payload) {
    return {
        type: RECEIVE_TOAST_MESSAGES,
        payload
    };
}
export function fetchCities() {
    return {
        types: [REQUEST_CITIES_DATA, RECEIVE_CITIES_DATA, ERROR_API_DATA],
        promise: fetchCitiesData()
    };
}
export function fetchGroupsCategories() {
    return {
        types: [REQUEST_GROUPS_CATEGORIES_DATA, RECEIVE_GROUPS_CATEGORIES_DATA, ERROR_API_DATA],
        promise: fetchGroupsCategoriesData()
    };
}
export function fetchUser() {
    return {
        types: [REQUEST_LOGIN_DATA, RECEIVE_LOGIN_DATA, ERROR_LOGIN_DATA],
        promise: fetchUserData()
    };
}
export function fetchCart() {
    return {
        types: [REQUEST_CART_DATA, RECEIVE_CART_DATA, ERROR_CART_DATA],
        promise: fetchCartData()
    };
}
export function fetchReprocessedCart() {
    return {
        types: [REQUEST_CART_DATA, RECEIVE_CART_DATA, ERROR_CART_DATA],
        promise: reprocessCart()
    };
}
export function clearCart() {
    return {
        types: [CLEAR_CART, CLEAR_CART_SUCCESS, CLEAR_CART_ERROR],
        promise: clearCartData()
    };
}
export function clearFavouritesData() {
    return {
        type: CLEAR_FAVOURITES_DATA
    };
}
export function fetchActiveFavourites() {
    return {
        types: [REQUEST_ACTIVE_FAVOURITES_DATA, RECEIVE_ACTIVE_FAVOURITES_DATA, ERROR_ACTIVE_FAVOURITES_DATA],
        promise: fetchFavouritesData()
    };
}
export function fetchPastFavourites() {
    return {
        types: [REQUEST_PAST_FAVOURITES_DATA, RECEIVE_PAST_FAVOURITES_DATA, ERROR_PAST_FAVOURITES_DATA],
        promise: fetchFavouritesData(queryString.stringify({ favourite_type: "past" }))
    };
}
export function removeCartItem(item, isFromApp) {
    return function (dispatch, getState) {
        dispatch({ type: REMOVE_CART_ITEM, payload: { item } });
        // If the item being removed is the final item, we have to make sure
        // we don't call the reprocess endpoint.
        const state = getState();
        const cartItems = state.getIn(["cartData", "cart", "items"]);
        const isFinalItem = cartItems && cartItems.size === 1;
        // This is what we'll send to the server. It contains the ID
        // of the item we want to update, and a bunch of fields describing
        // our change.
        let payload = fromJS({
            _id: item.get("_id")
        });
        const itemgroupType = item.get("itemgroup_type");
        if (itemgroupType && itemgroupType.toLowerCase() === "seats") {
            // If we're removing a collection of seats from the cart, we have to
            // construct an object that looks something like this:
            //
            //   {
            //     _id: <item id>,
            //     seats: [
            //       {name: 'k1', action: 'remove'},
            //       {name: 'k2', action: 'remove'},
            //       {name: 'k3', action: 'remove'},
            //     ]
            //   }
            //
            // We construct that object here.
            payload = payload.withMutations(p => p.set("seats", item.get("seats").map(seat => {
                return fromJS({ name: seat.get("name"), action: "remove" });
            })));
        }
        else {
            // If we're removing a normal item from cart, we just set its count
            // to 0. Sending tickets array to remove tickets from redis
            payload = payload.withMutations(p => {
                p.set("count", 0).set("tickets", item.get("tickets"));
            });
        }
        return updateCart(payload.toJS(), isFromApp)
            .then(response => {
            if (response.get("statusCode") !== 200) {
                throw new Error(response);
            }
            return response;
        })
            .then(response => {
            if (isFinalItem) {
                // If this was the final item, we construct a fake response containing
                // the same cart as before, but with zero items. We then return a
                // promise that resolves to that fake response.
                const cart = Map(state.getIn(["cartData", "cart"])).withMutations(c => c.set("items", List()));
                return Promise.resolve(cart);
            }
            // If this is not the final item, then we don't send the cart with 0 items, instead, just send back the updated cart
            return response.get("cartData");
        })
            .then(cart => {
            // Dispatchers be dispatchin'
            dispatch({ type: REMOVE_CART_ITEM_SUCCESS, payload: { item } });
            return dispatch({ type: RECEIVE_CART_DATA, payload: cart });
        })
            .catch(error => {
            dispatch({ type: REMOVE_CART_ITEM_ERROR, payload: error });
        });
    };
}
export function applyDiscountCoupon(coupon, isFromApp, extraInfo) {
    console.log("applyDiscountCoupon action", isFromApp);
    if (!coupon || !coupon.trim())
        return;
    return function (dispatch) {
        var _a;
        let body = { isFromApp };
        if (extraInfo && ((_a = Object.keys(extraInfo)) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            body = Object.assign(Object.assign({}, body), extraInfo);
        }
        dispatch({ type: APPLY_DISCOUNT_COUPON });
        return applyDiscountCouponApiCall(coupon.toUpperCase(), body)
            .then(response => {
            if (response.get("error")) {
                dispatch({ type: APPLY_DISCOUNT_COUPON_ERROR, error: response.get("error") });
                return {
                    status: 400,
                    error: response.get("error")
                };
            }
            const coupon = response.getIn(["cart", "coupon"]);
            const promoVerifyData = response.getIn(["cart", "promoVerifyData"]);
            if (coupon.get("status") !== 200 && (!promoVerifyData || promoVerifyData.size === 0)) {
                dispatch({ type: APPLY_DISCOUNT_COUPON_ERROR, error: coupon.get("error") });
                return {
                    status: coupon.get("status"),
                    error: coupon.get("error")
                };
            }
            dispatch({ type: APPLY_DISCOUNT_COUPON_SUCCESS });
            dispatch({ type: RECEIVE_CART_DATA, payload: response.get("cart") });
            return {
                status: coupon.get("status"),
                data: response.get("cart")
            };
        })
            .catch(error => {
            dispatch({ type: APPLY_DISCOUNT_COUPON_ERROR, error });
            return {
                status: 400,
                error: error
            };
        });
    };
}
export const removeDiscountCoupon = (isFromApp) => {
    return function (dispatch) {
        dispatch({ type: REMOVE_DISCOUNT_COUPON });
        removeDiscountCouponApiCall(isFromApp)
            .then(response => {
            dispatch({ type: REMOVE_DISCOUNT_COUPON_SUCCESS });
            return dispatch({ type: RECEIVE_CART_DATA, payload: response.get("cart") });
        })
            .catch(error => {
            return dispatch({ type: REMOVE_DISCOUNT_COUPON_ERROR, error });
        });
    };
};
export const setDeliveryDetails = (deliveryDetails, isFromApp) => {
    return function (dispatch) {
        dispatch({ type: SET_DELIVERY_DETAILS, payload: deliveryDetails });
        return updateDeliveryDetails(deliveryDetails, isFromApp)
            .then(response => {
            dispatch({ type: SET_DELIVERY_DETAILS_SUCCESS });
            return dispatch({ type: RECEIVE_CART_DATA, payload: response.get("cart") });
        })
            .catch(error => {
            dispatch({ type: SET_DELIVERY_DETAILS_ERROR, error });
            throw error;
        });
    };
};
export const clearDeliveryDetails = (isFromApp) => {
    return function (dispatch) {
        dispatch({ type: CLEAR_DELIVERY_DETAILS });
        return clearDeliveryDetailsApiCall(isFromApp)
            .then(response => {
            dispatch({ type: CLEAR_DELIVERY_DETAILS_SUCCESS });
            return dispatch({ type: RECEIVE_CART_DATA, payload: response.get("cart") });
        })
            .catch(error => {
            dispatch({ type: CLEAR_DELIVERY_DETAILS_ERROR, error });
            throw error;
        });
    };
};
export const updateItemsInCartAction = (items, isFromApp, embed, ref_source) => {
    return async function (dispatch) {
        try {
            const apiResponse = await updateItemsInCart(items.toJS(), isFromApp, embed, ref_source);
            if (apiResponse.has("error")) {
                throw new Error(apiResponse.get("error") || "Could not update items in cart! Please try again.");
            }
            const cart = apiResponse.get("cart");
            return dispatch({ type: RECEIVE_CART_DATA, payload: cart });
        }
        catch (err) {
            throw { message: err.message || err };
        }
    };
};
export const addItemToCart = (item, quantity, isFromApp, embed, ref_source) => {
    return function (dispatch) {
        dispatch({ type: ADD_ITEM_TO_CART, payload: { item } });
        dispatch({ type: CLEAR_ADD_ITEM_TO_CART_ERROR, payload: { item } });
        const itemToPost = item.withMutations(i => i.set("count", quantity));
        return (updateCart(itemToPost.toJS(), isFromApp, embed, ref_source)
            .then(response => {
            if (response.get("statusCode") !== 200) {
                throw new Error(response);
            }
            const cartDataItems = response.getIn(["cartData", "items"]);
            const itemAddAttempted = cartDataItems.find(cartDataItem => cartDataItem.get("id") === itemToPost.get("id"));
            if (itemAddAttempted) {
                if (itemAddAttempted.getIn(["result", "status"]) !== 200) {
                    dispatch({
                        type: ADD_ITEM_TO_CART_ERROR,
                        payload: { error: itemAddAttempted.getIn(["result", "result"]) }
                    });
                }
            }
            return response.get("cartData");
        })
            // .then((response) => {
            // 	// calling reprocess only if items in response cart is >1
            // 	const cartDataItems = response.getIn(["cartData", "items"]);
            // 	if(!(cartDataItems && cartDataItems.size > 0 && cartDataItems?.toJS()?.length > 1) && response.get('cartData')) return response.get('cartData');
            // 	else return reprocessCart(isFromApp);
            // })
            .then(cart => {
            dispatch({ type: ADD_ITEM_TO_CART_SUCCESS, payload: { item } });
            return dispatch({ type: RECEIVE_CART_DATA, payload: cart });
        })
            .catch(error => {
            let err;
            try {
                err = JSON.parse(error.message);
            }
            catch (parseError) {
                err = { status: 400, message: error.message };
            }
            dispatch({
                type: ADD_ITEM_TO_CART_ERROR,
                payload: {
                    error: err.message,
                    code: err.status,
                    item
                }
            });
            throw new Error(err.message);
        }));
    };
};
export const addItemsToCartSerially = (items, isFromApp, embed, ref_source, pickupArray) => async (dispatch, getState) => {
    const state = getState();
    let cartItems = state.getIn(["cartData", "cart", "items"]);
    if (cartItems)
        cartItems = cartItems.toJS();
    else
        cartItems = [];
    let cartAddonItems = state.getIn(["cartData", "cart", "addon_items"]);
    if (cartAddonItems)
        cartAddonItems = cartAddonItems.toJS();
    else
        cartAddonItems = [];
    let cartItemPickupArray = [];
    let itemPickupArray = [...(pickupArray !== null && pickupArray !== void 0 ? pickupArray : [])];
    let isCommonPickUpLocationPresent = true;
    cartItems.forEach(item => {
        var _a, _b, _c;
        const pickupObjArray = (_c = (_b = (_a = item === null || item === void 0 ? void 0 : item.parent) === null || _a === void 0 ? void 0 : _a.parent_id) === null || _b === void 0 ? void 0 : _b.pickup_information) !== null && _c !== void 0 ? _c : [];
        cartItemPickupArray = [...cartItemPickupArray, ...pickupObjArray];
    });
    for (const item of items) {
        try {
            const id = item.get("_id");
            const isGivenItemIsAddon = cartAddonItems.some((addonItem) => (addonItem === null || addonItem === void 0 ? void 0 : addonItem._id) === id);
            if (cartItems && cartItems.length > 0) {
                if (!pickupArray) {
                    const itemPickArray = item.getIn(["parent", "parent_id", "pickup_information"]);
                    if (itemPickArray)
                        itemPickupArray = [...itemPickArray.toJS()];
                }
                if (!isGivenItemIsAddon &&
                    ((itemPickupArray && itemPickupArray.length > 0) || cartItemPickupArray.length > 0)) {
                    const pickUpids = new Set(cartItemPickupArray.map(item => { var _a; return (_a = item === null || item === void 0 ? void 0 : item._id) !== null && _a !== void 0 ? _a : ""; }));
                    isCommonPickUpLocationPresent = itemPickupArray.some(pickObj => pickUpids.has(pickObj === null || pickObj === void 0 ? void 0 : pickObj._id));
                }
            }
            const event_state = item.get("event_state");
            if (!isGivenItemIsAddon && event_state !== "available") {
                const msg = "Selected event tickets are not available right now, please try again sometime later.";
                dispatch({
                    type: ADD_ITEM_TO_CART_ERROR,
                    payload: {
                        error: msg,
                        code: 400
                    }
                });
                throw new Error(msg);
            }
            if (!isCommonPickUpLocationPresent) {
                console.error("pickup info doesn't match");
                const msg = "You won't be able to purchase this ticket together with the tickets currently in your cart. Kindly make a separate purchase for each.";
                dispatch({
                    type: ADD_ITEM_TO_CART_ERROR,
                    payload: {
                        error: msg,
                        code: 400
                    }
                });
                throw new Error(msg);
            }
            else {
                await dispatch(addItemToCart(item, item.get("count"), isFromApp, embed, ref_source));
            }
        }
        catch (error) {
            console.error("Error adding item to cart:", error.message);
            throw new Error(error.message);
        }
    }
};
export const removeItemsFromCartSerially = (items, isFromApp) => async (dispatch) => {
    for (const item of items) {
        try {
            await dispatch(removeCartItem(item, isFromApp));
        }
        catch (error) {
            console.error("Error adding item to cart:", error.message);
            throw new Error(error.message);
        }
    }
};
export function clearAddToCartError() {
    return {
        type: CLEAR_ADD_ITEM_TO_CART_ERROR
    };
}
export const enableCancellationProtect = () => {
    return function (dispatch) {
        dispatch({ type: ENABLE_CANCELLATION_PROTECT });
        return enableCancellationProtectApiCall()
            .then(() => {
            return reprocessCart();
        })
            .then(cart => {
            dispatch({ type: ENABLE_CANCELLATION_PROTECT_SUCCESS });
            return dispatch({ type: RECEIVE_CART_DATA, payload: cart });
        })
            .catch(error => {
            dispatch({ type: ENABLE_CANCELLATION_PROTECT_ERROR, error });
            throw error;
        });
    };
};
export const disableCancellationProtect = () => {
    return (dispatch) => {
        dispatch({ type: DISABLE_CANCELLATION_PROTECT });
        return disableCancellationProtectApiCall()
            .then(() => {
            return reprocessCart();
        })
            .then(cart => {
            dispatch({ type: DISABLE_CANCELLATION_PROTECT_SUCCESS });
            return dispatch({ type: RECEIVE_CART_DATA, payload: cart });
        })
            .catch(error => {
            dispatch({ type: DISABLE_CANCELLATION_PROTECT_ERROR, error });
            throw error;
        });
    };
};
//Resets cart timer
export const resetCartTimer = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_CART_TIMER });
        try {
            await resetCartTimerApiCall();
            const cart = await reprocessCart();
            dispatch({ type: RESET_CART_TIMER_SUCCESS });
            return dispatch({ type: RECEIVE_CART_DATA, payload: cart });
        }
        catch (error) {
            //Silently fail and continue existing timer
            dispatch({ type: RESET_CART_TIMER_ERROR });
        }
    };
};
export function fetchUserBuyTogetherData(params) {
    return {
        types: [REQUEST_BTG_DATA, RECEIVE_BTG_DATA, ERROR_BTG_DATA],
        promise: fetchUserBuyTogetherDataFromApi(params)
    };
}
export function fetchModelDataForTag({ tagSlugs, models, dataId }) {
    return {
        types: [REQUEST_MODEL_DATA_FOR_TAG, RECEIVE_MODEL_DATA_FOR_TAG, ERROR_MODEL_DATA_FOR_TAG],
        promise: fetchTagData({ tagSlugs, models, dataId }),
        meta: tagSlugs[0]
    };
}
export function clearModelDataForTag({ slug }) {
    return {
        type: CLEAR_MODEL_DATA_FOR_TAG,
        payload: slug
    };
}
export function fetchStayInArticlesData() {
    return {
        types: [REQUEST_STAYIN_ARTICLE_DATA, RECEIVE_STAYIN_ARTICLE_DATA, ERROR_API_DATA],
        promise: fetchStayInArticles()
    };
}
export function fetchClub({ slug }) {
    return {
        types: [REQUEST_CLUB_DATA, RECEIVE_CLUB_DATA, ERROR_API_DATA],
        promise: fetchClubData({ slug })
    };
}
export function setClubData(payload) {
    return {
        type: RECEIVE_CLUB_DATA,
        payload
    };
}
export function clearClub({ slug }) {
    return {
        type: CLEAR_CLUB_DATA,
        payload: { slug }
    };
}
export function fetchPaymentSuccess(transactionId) {
    return {
        types: [REQUEST_PAYMENT_SUCCESS_DATA, RECEIVE_PAYMENT_SUCCESS_DATA, ERROR_API_DATA],
        promise: fetchPaymentSuccessData(transactionId)
    };
}
export function fetchPaymentStatus(orderId) {
    return {
        types: [REQUEST_PAYMENT_STATUS_DATA, RECEIVE_PAYMENT_STATUS_DATA, ERROR_API_DATA],
        promise: fetchPaymentStatusData(orderId)
    };
}
export function fetchTickets(page, limit) {
    return {
        types: [REQUEST_TICKETS_DATA, RECEIVE_TICKETS_DATA, ERROR_TICKETS_DATA],
        promise: fetchTicketsData(page, limit)
    };
}
export function fetchMoreTickets(page, limit) {
    return {
        types: [REQUEST_MORE_TICKETS_DATA, RECEIVE_MORE_TICKETS_DATA, ERROR_MORE_TICKETS_DATA],
        promise: fetchTicketsData(page, limit)
    };
}
export function fetchTicketsFromShortcode(shortcode) {
    return {
        types: [REQUEST_TICKETS_FROM_SHORTCODE, RECEIVE_TICKETS_FROM_SHORTCODE, ERROR_API_DATA],
        promise: fetchTicketsShortcode(shortcode)
    };
}
export function fetchStashes(_id) {
    return {
        types: [REQUEST_STASHES_DATA, RECEIVE_STASHES_DATA, ERROR_API_DATA],
        promise: fetchStashesData(_id)
    };
}
export function fetchStashBook() {
    return {
        types: [REQUEST_STASHBOOK_DATA, RECEIVE_STASHBOOK_DATA, ERROR_API_DATA],
        promise: fetchStashBookData()
    };
}
export function rehydrateRecentlyViewed() {
    return (dispatch, getState) => {
        return getFromCache("recentlyViewed")
            .then(payload => dispatch({
            type: REHYDRATE_RECENTLY_VIEWED,
            payload
        }))
            .then(() => {
            // While updating state, we take only valid events
            // Refresh the local cache to match the state
            const updatedState = getState();
            const recentlyViewed = getAllRecentlyViewedWithResourcesOnly(updatedState);
            // Update localStore to keep only valid events
            return setInCache("recentlyViewed", recentlyViewed).catch(e => console.error(e));
        })
            .catch(e => console.error(e));
    };
}
// We isolate the side effect of saving in the action
// creator.
// TODO - check here that if set in cache errors
// and we don't send back anything, does redux bonk
// because it checks action.type everywhere, and here
// action is undefined. In that case we'll have to pass
// some action called NOOP
export function addToRecentlyViewed({ key, eventData = Map({}), type = "event" }) {
    return (dispatch, getState) => {
        // first get the sync action out of the way
        dispatch({
            type: ADD_TO_RECENTLY_VIEWED,
            payload: { key, eventData, type }
        });
        const updatedState = getState();
        const isRehydrated = getRehydratedStatus(updatedState);
        // first fetch local storage data and add it to redux store
        if (!isRehydrated) {
            return (dispatch(rehydrateRecentlyViewed())
                // save the updated item to local storage
                .then(() => {
                // get the updated state since we added the recently viewed item
                const updatedStateAfterRehydration = getState();
                // we get the whole object
                const recentlyViewed = getAllRecentlyViewedWithResourcesOnly(updatedStateAfterRehydration);
                // side effect of saving updated state here
                return setInCache("recentlyViewed", recentlyViewed).catch(e => console.error(e));
            }));
        }
        else {
            const recentlyViewed = getAllRecentlyViewedWithResourcesOnly(updatedState);
            // side effect of saving updated state here
            return setInCache("recentlyViewed", recentlyViewed).catch(e => console.error(e));
        }
    };
}
export function rehydrateReminders() {
    return async (dispatch) => {
        try {
            const payload = await getFromCache("reminders");
            dispatch({
                type: REHYDRATE_REMINDERS,
                payload
            });
        }
        catch (error) {
            dispatch({
                type: REHYDRATE_REMINDERS_ERRORED,
                payload: error
            });
        }
    };
}
export function addReminder({ eventSlug, remindToPhone, reminderType = "events" }) {
    return async (dispatch, getState) => {
        dispatch({
            type: ADD_REMINDER,
            payload: { eventSlug, reminderType, remindToPhone }
        });
        let updatedState = getState();
        let isRehydrated = areRemindersRehydrated(updatedState);
        if (!isRehydrated) {
            try {
                await dispatch(rehydrateReminders());
                let updatedState = getState();
                let rehydratedReminders = getReminderPhoneAndResources(updatedState);
                await setInCache("reminders", rehydratedReminders);
            }
            catch (error) {
                dispatch({ type: ADD_REMINDER_ERRORED, payload: error });
            }
        }
        else {
            try {
                let rehydratedReminders = getReminderPhoneAndResources(updatedState);
                await setInCache("reminders", rehydratedReminders);
            }
            catch (error) {
                dispatch({ type: ADD_REMINDER_ERRORED, payload: error });
            }
        }
    };
}
export function setDataForExpress() {
    return async (userInfoFromPrimary) => {
        const customerId = userInfoFromPrimary.get("customerId");
        const firstName = userInfoFromPrimary.get("firstName");
        const lastName = userInfoFromPrimary.get("lastName");
        const hashedEncSsoToken = userInfoFromPrimary.get("hashedEncryptedSsoToken");
        try {
            await loginWithPaytmApi(customerId, firstName, lastName, hashedEncSsoToken);
            return;
        }
        catch (e) {
            // TODO: save this somewhere so that u can use it in ui
            console.error("Set Data For express Failed because ", e);
            throw e;
        }
    };
}
export function setUserLoginDataWrapper(dispatch) {
    return async (userInfo) => dispatch(setUserLoginData(fromJS(userInfo)));
}
export function syncExpressLoginInfo() {
    return async (dispatch) => {
        try {
            await syncUserData(dispatch(setDataForExpress), dispatch(setUserLoginDataWrapper));
        }
        catch (e) {
            console.error("syncUserData failed because ", e);
            dispatch({ type: ERROR_LOGIN_DATA });
            throw e;
        }
    };
}
/**
 * Fetch Active Offers for EDP
 * @param meta i.e. Event Slug
 * @param offerCondition contains the following fields
 * redeem_against_event_ids, redeem_against_category_ids, redeem_against_group_ids, redeem_against_tag_ids
 redeem_against_item_ids,
 */
//Converts the offerCondition arrays to string with the _ids joined by comma
const convertOfferConditionsToQueryString = offerConditions => {
    return queryString.stringify(offerConditions.map(value => value.toArray().join(",")).toObject());
};
//Take actions types to fetch all active offers on the EDP
const makeOfferActionCreator = ({ types }) => ({ meta, offerConditions }) => {
    const queryParams = convertOfferConditionsToQueryString(offerConditions);
    return {
        types: [...types],
        meta,
        promise: fetchActiveOffers(queryParams)
    };
};
// Fetches Active Offers for EDP
export const fetchDiscountActiveOffers = makeOfferActionCreator({
    types: [REQUEST_DISCOUNT_OFFERS, RECEIVE_DISCOUNT_OFFERS, ERROR_DISCOUNT_OFFERS]
});
export function syncUserInfoForPhoenix(shouldLogin = true) {
    return async (dispatch) => {
        try {
            getUserDetailsForApp().then(userData => {
                dispatch(setUserLoginData(fromJS(userData)));
                if (userData && (userData === null || userData === void 0 ? void 0 : userData.selected_city)) {
                    dispatch(updateSelectedCity(userData.selected_city));
                }
            }, () => {
                if (shouldLogin) {
                    loginForApp().then(result => {
                        if (result.success === true) {
                            getUserDetailsForApp().then(userData => {
                                dispatch(setUserLoginData(fromJS(userData)));
                            }, () => {
                                popWindowForApp();
                            });
                        }
                        else {
                            popWindowForApp();
                        }
                    }, () => {
                        popWindowForApp();
                    });
                }
            });
        }
        catch (e) {
            console.error("sync now failed because ", e);
            throw e;
        }
    };
}
export const setReportEventData = ({ eventName, eventURL }) => {
    return async (dispatch) => {
        await setInCache("reportEventData", { eventName, eventURL });
        return dispatch({
            type: SET_REPORT_EVENT_DATA,
            payload: { eventName, eventURL }
        });
    };
};
export function rehydrateReportEventData() {
    return async (dispatch) => {
        const payload = await getFromCache("reportEventData");
        try {
            dispatch({
                type: REHYDRATE_REPORT_EVENT_DATA,
                payload
            });
        }
        catch (error) {
            console.error("rehydrateReportEventData failed: ", error);
        }
    };
}
export function clearReportEventData() {
    return async (dispatch) => {
        await setInCache("reportEventData", {});
        return dispatch({
            type: CLEAR_REPORT_EVENT_DATA
        });
    };
}
export function fetchRelatedEvents(eventId, city, slug) {
    return {
        types: [REQUEST_RELATED_EVENTS_DATA, RECEIVE_RELATED_EVENTS_DATA, ERROR_API_DATA],
        promise: fetchRelatedEventsData(eventId, city),
        meta: { slug }
    };
}
export function toggleBodyScroll(lockBodyScroll = false) {
    return {
        type: TOGGLE_BODY_SCROLL,
        payload: lockBodyScroll
    };
}
export function setSocialLoginFlag() {
    return {
        type: SET_SOCIAL_LOGIN_FLAG,
        payload: true
    };
}
export function fetchEventAdBanner(eventId, city, slug) {
    return {
        types: [REQUEST_EVENT_AD_BANNER_DATA, RECEIVE_EVENT_AD_BANNER_DATA, ERROR_API_DATA],
        promise: fetchEventAdBannerData(eventId, city),
        meta: { slug }
    };
}
export function fetchMatchesEvents() {
    return {
        types: [REQUEST_MATCH_SCHEDULE_EVENTS_DATA, RECEIVE_MATCH_SCHEDULE_EVENTS_DATA, ERROR_MATCH_SCHEDULE_EVENTS_DATA],
        promise: fetchMatchScheduleEvents()
    };
}
export function fetchIPLMatchesEvents() {
    return {
        types: [
            REQUEST_IPL_MATCH_SCHEDULE_EVENTS_DATA,
            RECEIVE_IPL_MATCH_SCHEDULE_EVENTS_DATA,
            ERROR_IPL_MATCH_SCHEDULE_EVENTS_DATA
        ],
        promise: fetchIPLMatchScheduleEvents()
    };
}
export function fetchQuickSearchFilters() {
    return {
        types: [ERROR_QUICK_SEARCH_FILTER, RECEIVE_QUICK_SEARCH_FILTER],
        promise: fetchConfigByName("quick_search_filters")
    };
}
export function setFilterApplied(name = "All") {
    return {
        type: SET_SEARCH_FILTER_APPLIED,
        payload: name
    };
}
export function fetchAnuvJainEvents(tagId, startTime) {
    return {
        types: [
            REQUEST_DYNAMIC_LANDING_PAGE_EVENTS_DATA,
            RECEIVE_DYNAMIC_LANDING_PAGE_EVENTS_DATA,
            ERROR_DYNAMIC_LANDING_PAGE_EVENTS_DATA
        ],
        promise: fetchDynamicEvents(tagId, startTime)
    };
}
export const fetchPopularDataFromMasterList = (type = "All", value = "") => {
    return async (dispatch, getState) => {
        let response = {
            popular: []
        };
        let popularList = [];
        const state = getState();
        let masterList = getElpMasterList(state);
        const city = getSelectedCityName(state);
        if (!masterList.size) {
            await dispatch(fetchGooutELPResourcesWithoutPopular(city));
            const updatedState = getState();
            masterList = getElpMasterList(updatedState);
        }
        const maxPopularEvents = 10;
        for (const [_, event] of masterList) {
            if (event.get("popularity_score") > 0) {
                switch (type) {
                    case "categoryFilter":
                        event.get("category_id").get("_id") === value && popularList.push(event);
                        break;
                    case "priceFilter":
                        event.get("min_price") <= value && popularList.push(event);
                        break;
                    case "timeFilter":
                        event.get("applicable_filters").includes(value) && popularList.push(event);
                        break;
                    case "tagFilter":
                        event.get("tags").some(tag => {
                            if (tag.get("tag_id") === value) {
                                popularList.push(event);
                                return true;
                            }
                        });
                        break;
                    case "groupFilter":
                        event.get("group_id").get("_id") === value && popularList.push(event);
                        break;
                    case "All":
                        popularList.push(event);
                        break;
                    default:
                        break;
                }
            }
        }
        response["popular"] = popularList
            .sort((a, b) => b.get("popularity_score") - a.get("popularity_score"))
            .slice(0, maxPopularEvents);
        return dispatch({
            type: RECEIVE_POPULAR_DATA,
            payload: fromJS(response)
        });
    };
};
export function fetchNewYearEventsBasedOnCity(tagId) {
    return {
        types: [
            REQUEST_NEWYEAR_LANDING_PAGE_EVENTS_DATA,
            RECEIVE_NEWYEAR_LANDING_PAGE_EVENTS_DATA,
            ERROR_NEWYEAR_LANDING_PAGE_EVENTS_DATA
        ],
        promise: fetchNewYearEvents(tagId)
    };
}
export const fetchNewYearOffer = (queryParams) => {
    return {
        types: [REQUEST_NEWYEAR_OFFER_DATA, RECEIVE_NEWYEAR_OFFER_DATA, ERROR_NEWYEAR_OFFER_DATA],
        promise: fetchNewYearOffersData(queryParams)
    };
};
export function fetchMumbaiFestEvents(tagId, startTime) {
    return {
        types: [REQUEST_MUMBAI_FEST_EVENTS_DATA, RECEIVE_MUMBAI_FEST_EVENTS_DATA, ERROR_MUMBAI_FEST_EVENTS_DATA],
        promise: fetchDynamicEvents(tagId, startTime)
    };
}
export const setIsChangedCity = (isChanged) => {
    return {
        type: IS_CITY_CHANGED,
        payload: isChanged
    };
};
export const fetchHoli2024Offer = (queryParams) => {
    return {
        types: [REQUEST_HOLI24_OFFER_DATA, RECEIVE_HOLI24_OFFER_DATA, ERROR_HOLI24_OFFER_DATA],
        promise: fetchNewYearOffersData(queryParams)
    };
};
export function fetchMicrositeScheduleData(tagIds, slug) {
    return {
        types: [
            REQUEST_MICROSITE_LANDING_PAGE_SCHEDULE_DATA,
            RECEIVE_MICROSITE_LANDING_PAGE_SCHEDULE_DATA,
            ERROR_MICROSITE_LANDING_PAGE_SCHEDULE_DATA
        ],
        promise: fetchDynamicScheduleData(tagIds),
        meta: slug,
        dataCategory: "scheduleData"
    };
}
export function fetchMicrositePageData(slug) {
    return {
        types: [
            REQUEST_MICROSITE_LANDING_PAGE_DATA,
            RECEIVE_MICROSITE_LANDING_PAGE_DATA,
            ERROR_MICROSITE_LANDING_PAGE_DATA
        ],
        promise: fetchDynamicPageData(slug),
        meta: slug,
        dataCategory: "pageData"
    };
}
/**
 * Its a function that fetches if a device is supported by Samsung Wallet or not
 * by first checking in local storage and if undefined/null, calls API and set result in local storage
 **/
export const fetchIsSamsungWalletSupported = () => async (dispatch) => {
    var _a;
    try {
        let shouldCallApi = true;
        let isSupported = false;
        if (typeof window !== "undefined" && typeof window.localStorage !== "undefined") {
            const valInLocalStorage = window.localStorage.getItem("isSamsungSupportedDevice");
            if (valInLocalStorage) {
                shouldCallApi = false;
                isSupported = valInLocalStorage === "y";
            }
        }
        if (checkPhoenixViaUserAgent()) {
            const os = await getOS();
            const isAndriod = os === "android";
            if (shouldCallApi && isAndriod) {
                let deviceName = null;
                const deviceNameFromApp = await getDeviceName();
                deviceName = deviceNameFromApp || deviceName;
                if (deviceName) {
                    const response = await isSamsungWalletSupported(deviceName);
                    if (response) {
                        isSupported = (_a = response === null || response === void 0 ? void 0 : response.data) !== null && _a !== void 0 ? _a : false;
                        const valToBeSetInLocalStorage = isSupported ? "y" : "n";
                        if (typeof window !== "undefined" && typeof window.localStorage !== "undefined") {
                            window.localStorage.setItem("isSamsungSupportedDevice", valToBeSetInLocalStorage);
                        }
                    }
                }
            }
        }
        dispatch({ type: IS_SAMSUNG_WALLET_SUPPORTED, payload: isSupported });
    }
    catch (e) {
        console.error("fetchIsSamsungWalletSupported failed because ", e);
        // throw e;
    }
};
