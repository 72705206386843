/** @jsx jsx */
import React, { useCallback, useEffect, useState } from "react";
import { jsx, css } from "@emotion/core";
import DynamicFaqsList from "./DynamicFaqsList";
import { colors, mediaFeatures, typography } from "../../../styles/common";
import { isH5Build } from "../../../helpers/misc";
const ISLFooter = ({ isFromApp = false }) => {
    const [data, setData] = useState([]);
    const getFaqs = useCallback(async () => {
        fetch('/get-freshdesk-solutions?folderId=88000175587')
            .then(async (res) => {
            const response = await res.json();
            if (Array.isArray(response)) {
                setData(response);
            }
        })
            .catch(err => {
            console.log(err);
        });
    }, []);
    useEffect(() => {
        getFaqs();
    }, []);
    return (<div css={css([footerContainer])}>
      {data.length > 0 && <React.Fragment>
          <DynamicFaqsList title="FAQ'S" filter={4} queKey="title" ansKey="description" list={data} isAnsHtml={true}/>
        <div css={css([afterFaqCont])}>
          <p css={css([bottomText])}>Didn’t find answer to your query in above list?</p>
          <a {...!(isFromApp || isH5Build) && { target: '_blank' }} href="https://paytminsider.freshdesk.com/support/solutions/folders/88000175587/page/1?category_id=88000060084" css={css([readMore])}>
            Read more
          </a>
        </div>
      </React.Fragment>}
      {/* this section is a placeholder for sponsers */}

      {/* <div css={css([sponsers])}>
          <a>
            <ISLImage src='/assets/images/ISL/heroBanner.png' style='height:180px;border-radius: 0;'/>
          </a>
          <div css={css([sponserFlex])}>
            <a>
              <ISLImage src='/assets/images/ISL/heroBanner.png' style='height:140px;border-radius: 0;'/>
            </a>
            <a>
              <ISLImage src='/assets/images/ISL/heroBanner.png' style='height:140px;border-radius: 0;'/>
            </a>
          </div>
        </div> */}
    </div>);
};
export default ISLFooter;
const footerContainer = `
  max-width: 1260px;
  margin: 30px auto;
  padding: 0 6px;
  @media screen and ${mediaFeatures['for-mobile-and-tablet']} { 
    padding: 0 15px;
  }
`;
const afterFaqCont = `
  display: flex;
  align-items: center;
  justify-content: end;
  @media screen and ${mediaFeatures['for-mobile-and-tablet']} { 
      justify-content: space-between;
  }
`;
const bottomText = `
  font-family: ${typography.chromeBody["font-family"]};
  font-size: 16px;
  font-weight: 400;
  margin-right: 15px;
  margin-left: auto;
  @media screen and ${mediaFeatures['for-mobile-and-tablet']} { 
    font-size: 14px;
    flex: 1;
  }
`;
const readMore = `
  font-family: ${typography.chromeBody["font-family"]};
  font-size: 14px;
  font-weight: 500;
  color: ${colors.primaryDark};
  background: ${colors.faintGrey};
  border-radius: 100px;
  padding:8px 15px;
  border: 1px solid ${colors.faintGrey};
  @media screen and ${mediaFeatures['for-mobile-and-tablet']} { 
      font-size: 14px;
  }
`;
// const sponsers = `
//   margin: 30px 0 15px;
//   text-align: center;
//   a {
//     margin: 30px 0;
//     border-radius: 12px;
//     border: 1px solid ${colors.faintGrey};
//   }
// `;
// const sponserFlex = `
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   gap:30px;
// `;
const disclaimer = `
  margin: 30px 0 15px;
  font-family: ${typography.chromeBody["font-family"]};
  font-size: 14px;
  font-weight: 400;
  color: ${colors.lightGray};
  text-align: center;
`;
